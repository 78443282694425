<template>
  <v-container class="mx-lg-6 pt-xs-0 pt-lg-7">
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="9">
        <v-row class="my-2">
          <v-col cols="12">
            <v-img
                :height="['xs', 'sm', 'md'].includes($vuetify.breakpoint.name) ? 80 : 150"
                :src="require('@/assets/alkis.png')"
                alt="Ausschnitt Kartendarstellung des Liegenschaftskatasters"
            ></v-img>
          </v-col>
          <div class="mx-3">
            <p>
              Die Web-Anwendung <span style="font-weight: bolder">Portal Vermessungsunterlagen</span> stellt einen
              einheitlichen Zugriff auf die bei den
              Kreisen und kreisfreien Städten als Katasterbehörden dezentral gespeicherten Daten des
              Liegenschaftskatasters
              bereit. Zu diesen Daten gehören beispielsweise Vermessungsrisse, ALKIS Bestandsdaten (Grundrissdaten) und
              AP
              Karten.</p>

            <p>
              Bei den Vermessungsrissen handelt es sich um fachlich zu interpretierende Daten, die eine qualifizierte
              und
              sorgsame Verwendung voraussetzen.
            </p>
            <p>
              Das Portal Vermessungsunterlagen NRW bietet zwei Zugänge: </p>
            <ul>
              <li class="mt-2"><b>Offener Zugang (Open Data)</b> mit einem reduzierten Produktangebot
                <a
                    @click="showProductInfo=!showProductInfo">
                  <v-icon class="ml-5 mr-1 mt-n1" small
                          color="#3C506E"
                  >mdi-information-outline
                  </v-icon>
                  Produktinformationen <span v-if="!showProductInfo">anzeigen</span>
                  <span v-else>ausblenden</span></a></li>
              <v-card color="#E5EDF6" class="ma-4 mr-6 elevation-3" v-if="showProductInfo">
                <VListProductInfo/>
              </v-card>
              <li class="mt-2"><b>Geschützter Zugang</b> mit einem erweiterten Produkt- und Funktionsangebot. Zugang
                können Öffentlich
                bestellte Vermessungsingenieurinnen und Öffentlich
                bestellte Vermessungsingenieure sowie andere behördliche
                Vermessungsstellen über ihre zuständige Aufsichtsbehörde beantragen.
              </li>
            </ul>
          </div>
        </v-row>
      </v-col>
      <v-spacer/>
    </v-row>
    <!--    Meldungen Landesportal mit allgemeiner Sichtbarkeit-->
    <v-row class="mt-4" v-if="messagesITNRW">
      <v-spacer/>
      <v-col sm="11" xl="9">
        <MessagesITNRW :messages="messagesITNRW"/>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row class="mb-4 mt-6" justify="center">
      <v-btn color="#3C506E" dark class="mx-6 elevation-6" large
             to="/opendata">
        <v-icon left>mdi-cube-outline</v-icon>
        <div style="text-align: center;"><span style="font-size: larger">Offener Zugang<br/>(Open Data)</span></div>
      </v-btn>
      <v-btn color="#3C506E" dark class="mx-6 elevation-6" large href="/ASWeb/ASC_URM/portallogin.jsp">
        <v-icon left>mdi-lock-outline</v-icon>
        <div style="text-align: center;"><span style="font-size: larger">Geschützter Zugang <br/>(registrierte Vermessungsstelle)</span>
        </div>
      </v-btn>
    </v-row>
    <v-row class="mt-6" justify="center">
      <v-col sm="11" xl="9">
        <span style="color: green">
          Aktuelle Information:
          Die Kartenansicht der Liegenschaftskarte ist aufgrund einer Modellumstellung mit Stand 01.09.2023 eingefroren und wird voraussichtlich erst Mitte 2024 wieder aktualisiert. Die ALKIS Bestandsdaten im Download werden weiterhin aktuell durch die Katasterbehörden bereitgestellt.
<br><br>
              Bei Bedarf kann ein aktueller WMS-Dienst der Liegenschaftskarte der jeweiligen Katasterbehörde
              hinzugeladen
          werden (siehe <router-link to="/hilfe">Hilfe & Kontakt</router-link>). Eine Übersicht über die WMS-Dienste der Katasterbehörden ist auf der
              Internetseite von Geobasis NRW verfügbar:
              <a href="https://www.bezreg-koeln.nrw.de/geobasis-nrw/produkte-und-dienste/liegenschaftskataster/liegenschaftskataster-primaerdaten-der"
                 target="_blank">Liegenschaftskataster – Primärdaten der Katasterbehörden</a>
        </span>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>

import VListProductInfo from "@/components/VListProductInfo";
import axios from "axios";
import {API} from "@/config";
import MessagesITNRW from "@/components/MessagesITNRW";

export default {
  components: {
    VListProductInfo,
    MessagesITNRW
  },
  data() {
    return {
      showProductInfo: false,
      messages: [],
    }
  },
  methods: {
    getMessages() {
      axios.post(`${API.url}${API.getMessages}`).then((response) => {
        this.messages = response.data.features;
      })

    },
  },
  computed: {
    messagesITNRW() {
      return this.messages.filter(message => (message.properties.kn === '05000000' && message.properties.sichtbarkeit === 'allgemein'));
    },
  },
  mounted() {
    this.getMessages();
  },
}
</script>
